import { useMemo } from "react";
import useSWR from "swr";
import { get, post } from "./HttpHelpers";


export const usePost = (ListUrl, queryParams) => {
    const fetcher = () => post(ListUrl, { ...queryParams });
    const swr = useSWR(ListUrl, fetcher);
    return {
        ...swr,
        isLoading: !swr.data && !swr.error
    };
};

export const useGetFilter = (ListUrl, queryParams, fetch=true) => {
    const fetcher = () => get(`${ListUrl}?filter=${encodeURIComponent(JSON.stringify(queryParams))}`);
    const swr = useSWR(fetch ? ListUrl+encodeURIComponent(JSON.stringify(queryParams)) : null, fetcher);
    
    const isLoading = useMemo(() => !swr.data && !swr.error, [swr]);

    return {
        ...swr,
        isLoading
    };
};

export const useGet = (ListUrl, queryParams, fetch=true) => {
    const fetcher = () => get(`${ListUrl}?${encodeURIComponent(queryParams)}`);
    const swr = useSWR(fetch ? ListUrl+encodeURIComponent(queryParams) : null, fetcher);
    return {
        ...swr,
        isLoading: !swr.data && !swr.error
    };
};
