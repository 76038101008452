import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import history from "./history";
import store, { persistor } from "./store";
import Layout from "components/layout";
import Theme from "components/template/Theme";
import { FilterProvider } from "context/FilterProvider";

const App = () => (
    <Provider store={ store }>
        <PersistGate loading={ null } persistor={ persistor }>
            <BrowserRouter history={ history }>
                <Theme>
                    <FilterProvider>
                        <Layout />
                    </FilterProvider>
                </Theme>
            </BrowserRouter>
        </PersistGate>
    </Provider>
);

export default App;
