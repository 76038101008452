import axios from "axios";

import {
    errorHandler,
    requestHandler,
    responseErrorHandler,
    responseSuccessHandler
} from "./Interceptors";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
});

instance.interceptors.request.use(
    (config) => requestHandler(config),
    (error) => errorHandler(error)
);
instance.interceptors.response.use(
    (response) => responseSuccessHandler(response),
    (error) => responseErrorHandler(error)
);
export default instance;
