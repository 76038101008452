import { statusOptions } from "constants/table.constant";
import React from "react";
import { HiOutlineSearch, HiPlusCircle } from "react-icons/hi";
import { Link } from "react-router-dom";
import { Button, Input, Select } from "components/ui";
import { FilterCustomControl, FilterSelectOption } from "helper";

const DefaultTableTools = ({ queryParams, setQueryParams, pageName, filterName="name", showAdd=true }) => {

    const handleInputChange = (e) => {
        setQueryParams({ ...queryParams, filter: { ...queryParams?.filter, [filterName]: e.target.value } });
    };

    const onStatusFilterChange = (selected) => {
        setQueryParams({ ...queryParams, filter: { ...queryParams?.filter, active: selected?.value } });
    };
    
    return (
        <div className="flex flex-col lg:flex-row lg:items-center">
            <div className="flex flex-col lg:flex-row lg:items-center">
                <Input
                    className="max-w-md md:w-52 md:mb-0 mb-4"
                    size="sm"
                    placeholder={ `Search ${pageName}` }
                    prefix={ <HiOutlineSearch className="text-lg" /> }
                    onChange={ handleInputChange }
                />
                <Select
                    options={ statusOptions }
                    size="sm"
                    placeholder="Select Status"
                    className="min-w-[130px] md:mx-2 md:mb-0 mb-4"
                    onChange={ onStatusFilterChange }
                    components={ {
                        Control: FilterCustomControl,
                        Option: FilterSelectOption
                    } }
                    value={ queryParams?.filter?.active ? 
                        statusOptions.filter((option) => option.value === queryParams?.filter?.active?.toString()):
                        statusOptions[0] }
                />
            </div>
            { showAdd && <Link
                className="block lg:inline-block md:mb-0 mb-4"
                to="add"
            >
                <Button block variant="solid" size="sm" icon={ <HiPlusCircle /> }>
                    { `Add ${pageName}` }
                </Button>
            </Link> }
        </div>
    );
};

export default DefaultTableTools;
