import instance from "./Axios";

const post = (endpoint, credentials = null, config=null) =>
    instance.post(endpoint, credentials, config);

const put = (endpoint, credentials = null, config=null) =>
    instance.put(endpoint, credentials, config);

const delete_ = (endpoint, credentials = null, config=null) =>
    instance.delete(endpoint, credentials, config);

const get = (endpoint, config=null) => {
    return instance.get(endpoint, config);
};

export { get, post, put, delete_ };
