import { Button } from "components/ui";

const AddUpdateDrawerFooter = ({ onSaveClick, onCancel }) => {
    return (
        <div className="text-right w-full">
            <Button size="sm" className="mr-2" onClick={ onCancel }>
                Cancel
            </Button>
            <Button size="sm" variant="solid" onClick={ onSaveClick }>
                Save
            </Button>
        </div>
    );
};

export default AddUpdateDrawerFooter;
