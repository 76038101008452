import { REQUEST_HEADER_AUTH_KEY, TOKEN_TYPE } from "constants/api.constant";
import { PERSIST_STORE_NAME } from "constants/app.constant";
import deepParseJson from "utils/deepParseJson";
import instance from "./Axios";
import { post } from "./HttpHelpers";
import store from "../store";

let isRefreshing = false;
const refreshSubscribers = [];
const subscribeTokenRefresh = cb => {
  refreshSubscribers.push(cb);
};

const onRefreshed = token => {
  refreshSubscribers.map(cb => cb(token));
};

const requestHandler = async (config) => {
    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
    const persistData = deepParseJson(rawPersistData);

    let accessToken = persistData.auth.session.token?.access_token;
    let refreshToken = persistData.auth.session.token?.refresh_token;

    if (!accessToken) {
        const { auth } = store.getState();
        accessToken = auth.session.access_token;
        refreshToken = auth.session.refresh_token;
    }

    if (accessToken) {
        if (config.url.indexOf("/authentication/back-office/refresh") === -1) {
        config.headers[
            REQUEST_HEADER_AUTH_KEY
        ] = `${TOKEN_TYPE}${accessToken}`;
        }
        if (config.url.indexOf("/authentication/back-office/refresh") !== -1) {
            config.headers[
                REQUEST_HEADER_AUTH_KEY
            ] = `${TOKEN_TYPE}${refreshToken}`;
            }
    }

    return config;
};

const responseSuccessHandler = (response) => {
    return response.data;
};

const responseErrorHandler = async (error) => {
    const {
        config,
        response: { status }
      } = error;
      const originalRequest = config;
      if (status === 401) {
        if (!isRefreshing) {
            isRefreshing = true;
            post("/authentication/back-office/refresh")
            .then(async response => {
              const { result } = response;
              console.log(result, "refresh");
              isRefreshing = false;
              onRefreshed(result?.access_token);
            });
        }
        const retryOrigReq = new Promise((resolve, reject) => {
            subscribeTokenRefresh(token => {
              originalRequest.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${token}`;
              resolve(instance(originalRequest));
            });
          });
          return retryOrigReq;
      }
      
    return Promise.reject(error);
};

const errorHandler = async (error) => {
    return Promise.reject(error);
};

export {
    requestHandler,
    errorHandler,
    responseSuccessHandler,
    responseErrorHandler
};
