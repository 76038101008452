/* eslint-disable max-len */
import { REDIRECT_URL_KEY } from "constants/app.constant";
import axios from "axios";
import appConfig from "configs/app.config";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import { initialState, setUser } from "store/auth/userSlice";
import useQuery from "./useQuery";
import { get, post } from "helper";

function useAuth() {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const query = useQuery();
    const queryToken = useMemo(() => query.get("token"), [query]);

    const { fromQuery } = useSelector((state) => state.auth.user);
    const { token, signedIn } = useSelector((state) => state.auth.session);
    const [weather, setWeather] = useState();

    const handleToken = async () => {
        if (!queryToken) return;
        try {
            dispatch(onSignOutSuccess());
            dispatch(setUser(initialState));
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/authentication/back-office/me`, {}, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${queryToken}`
                }
            });
            const { data: { result: { user, tokens }, success } } = response;
            if (!success) {
                signOut();
            }
            dispatch(onSignInSuccess(tokens));
            dispatch(
                setUser({ authority: user?.roles?.map(el => el?.slug), ...user, fromQuery: true })
            );
        } catch (error) {
            signOut();
        }

    };

    useEffect(() => {
        handleToken();
    }, [queryToken]);

    const handleAuth = async () => {
        try {
            const response = await post("/authentication/back-office/me");
            const { result: { user, tokens }, success } = response;
            if (!success) {
                signOut();
            }
            dispatch(onSignInSuccess(tokens));
            dispatch(
                setUser(
                    { authority: user?.roles?.map(el => el?.slug), ...user, fromQuery } || {
                        authority: ["USER"],
                        avatar: "",
                        email: ""
                    }
                )
            );
        } catch (error) {
            signOut();
        }

    };
    const fetchWeatherData = async () => {
        try {
            const { result } = await get("/weather");
            setWeather(result);
        } catch (error) {
            console.error("Error fetching weather data:", error);
        }
    };
    useEffect(() => {
        fetchWeatherData();
    }, []);

    const signIn = async (values) => {
        try {
            const { result, success, message } = await post("/authentication/back-office/login", values);
            if (!success) {
                return {
                    message,
                    status: "failed"
                };
            }
            const { user, tokens } = result;
            dispatch(onSignInSuccess(tokens));
            dispatch(
                setUser(
                    { authority: user?.roles?.map(el => el?.slug), ...user, fromQuery }
                )
            );
            const redirectUrl = query.get(REDIRECT_URL_KEY);
            navigate(
                redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
            );
            return {
                message: "",
                status: "success"
            };

        } catch (errors) {
            return {
                message: errors?.response?.data?.message || errors.toString(),
                status: "failed"
            };
        }
    };

    const handleSignOut = () => {
        dispatch(onSignOutSuccess());
        dispatch(setUser(initialState));
        navigate(appConfig.unAuthenticatedEntryPath);
    };

    const signOut = async () => {
        handleSignOut();
    };

    return {
        authenticated: token && signedIn,
        handleAuth,
        signIn,
        signOut,
        weather

    };
}

export default useAuth;
