const appConfig = {
    apiPrefix: process.env.REACT_APP_API_URL,
    authenticatedEntryPath: "/dashboard",
    unAuthenticatedEntryPath: "/sign-in"
};
    



export default appConfig;
