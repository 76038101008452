/* eslint-disable react-hooks/exhaustive-deps */
import { DataTable } from "components/shared";

const Table = ({ queryParams, setQueryParams, 
    data, isLoading, pageIndex, pageSize, columns, 
    skeletonAvatarColumns, initialSorting={ id: "id", desc: true } }) => {

    const _onPaginationChange = (value) => setQueryParams({ ...queryParams, pageNumber:value });

    const _onSelectChange = (value) => setQueryParams({ ...queryParams, pageSize:value });

    const _onSort = (value) => setQueryParams({ ...queryParams, sortField:value?.key, sortOrder:value?.order });
    
    return (
        <DataTable
            columns={ columns }
            initialSorting={ initialSorting }
            data={ data?.result }
            skeletonAvatarColumns={ skeletonAvatarColumns }
            skeletonAvatarProps={ { height: 28 , width: 28 } }
            loading={ isLoading }
            pagingData={ { pageIndex, pageSize, total:data?.total_count } }
            onPaginationChange={ _onPaginationChange }
            onSelectChange={ _onSelectChange }
            onSort={ _onSort }
        />
    );
};

export default Table;
