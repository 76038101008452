import dayjs from "dayjs";
import { useMemo } from "react";
import NumberFormat from "react-number-format";
import useResponsive from "utils/hooks/useResponsive";
import { Loading } from "components/shared";
import { Card } from "components/ui";


const StatisticCard = ({ icon = () => null,currency=false,data = {}, label, startDate = null, endDate= null, 
    bodyClass= "bg-gradient-to-r from-violet-600 to-indigo-600 rounded", onClick = () => null ,isLoading }) => {
    const HTag = useMemo(() => `h${startDate && endDate ? 3 : 1}`, [startDate, endDate]);
    const { smaller } = useResponsive();

    return (
        <Card style={ smaller?.md ? {}: { cursor:"pointer" } } 
            className="border-none" bodyClass={ bodyClass } onClick={ () => !smaller?.md && onClick() }>
            <Loading loading={ isLoading }>
                <div className="flex items-center">
                    { icon() }
                    <div>
                        <h6 className="font-semibold text-white">{ label }</h6>
                        <div className="flex justify-between items-center">
                            <div>
                                <HTag className="font-bold text-white">
                                    <NumberFormat
                                        displayType="text"
                                        value={ currency ? data?.value.toFixed(2) : data?.value }
                                        prefix={ currency && "$" }
                                        thousandSeparator
                                    />
                                </HTag>
                                { startDate && endDate && <p>
                                    <span className="text-white">Between{ " " }</span>
                                    <span className="font-semibold text-white">
                                        { dayjs(startDate).format("DD MMM") }
                                    </span>
                                    { " - " }
                                    <span className="font-semibold text-white">
                                        { dayjs(endDate).format("DD MMM") }
                                    </span>
                                </p> }
                            </div>
                        </div>
                    </div>
                </div>
            </Loading>
            
            
        </Card>
    );
};

export default StatisticCard;
