import React from "react";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { useConfig } from "../ConfigProvider";

const Sorter = ({ sort }) => {
    const { themeColor, primaryColorLevel } = useConfig();

    const color = `text-${themeColor}-${primaryColorLevel}`;

    const renderSort = () => {
        if (typeof sort !== "boolean") {
            return <FaSort />;
        }
        return sort ? (
            <FaSortDown className={ color } />
        ) : (
            <FaSortUp className={ color } />
        );
    };

    return <div className="inline-flex">{ renderSort() }</div>;
};

export default Sorter;
