/*eslint no-unsafe-optional-chaining: "off"*/
import { statusColor, statusText } from "constants/table.constant";
import { useEffect, useState } from "react";
import { FaMobileAlt } from "react-icons/fa";
import { HiCheck, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { components } from "react-select";
import useThemeClass from "utils/hooks/useThemeClass";
import { ConfirmDialog } from "components/shared";
import {  Avatar, Badge, Notification, toast } from "components/ui";
import { delete_ } from "helper";

const { Control } = components;

export const handleTableChange = (setQueryParams) => {
    return (pagination, filters, sorter) => {
        const pageNumber = pagination.current || 1;
        const pageSize = pagination.pageSize || 10;
        const sortField = sorter?.field || "id";
        const sortOrder = sorter?.order === "ascend" ? "asc" : "desc" || "desc";
        setQueryParams((prev) => ({
            ...prev,
            sortField,
            sortOrder,
            pageNumber,
            pageSize
        }));
    };
};

export const formatResult = (datas = [], label="name", value="id", all=false) => {
    if(!all){
        return datas?.map((item) => ({ label: item[label], value: item[value] }));
    }
    return [{ value: null, label: "All" }, ...datas?.map((item) => ({ label: item[label], value: item[value] }))];
};

export const formatDeviceResult = (datas) => {
    return datas?.map((item) => ({ label: item.serial_number, value: item.id, gsm:item.sim_card.gsm }));
};

export const formatSimCardResult = (datas) => {
    return datas?.map((item) => ({ label: item?.gsm, value: item?.id }));
};



const ActionColumn = ({ row, showEdit }) => {
    const { textTheme } = useThemeClass();
    const navigate = useNavigate();

    const onEdit = () => {
        navigate(`${row.id}/edit`);
    };

    const onDelete = () => {
        navigate(`${row.id}/delete`);
    };

    return (
        <div className="flex justify-end text-lg">
            { showEdit && <span
                className={ `cursor-pointer p-2 hover:${textTheme}` }
                onClick={ onEdit }
            >
                <HiOutlinePencil />
            </span> }
            <span
                className="cursor-pointer p-2 hover:text-red-500"
                onClick={ onDelete }
            >
                <HiOutlineTrash />
            </span>
        </div>
    );
};

export const getColumns = (cols = [], showEdit=true) => {
    const columns = [
        {
            accessorKey: "id",
            header: "ID"
        }
    ];
    
    const actionColumns = [
        {
            accessorKey: "active",
            cell: (props) => {
                const row = props.row.original;
                return (
                    <div className="flex flex-col-2 items-start">
                        <div className="flex items-center">
                            <Badge className={ statusColor[row.active] } />
                            <span className="ml-2 rtl:mr-2 capitalize">
                                { statusText[row.active] }
                            </span>
                        </div>
                    </div>
                );
            },
            header: "Status"

        },
        {
            cell: (props) => <ActionColumn showEdit={ showEdit } row={ props.row.original } />,
            header: "",
            id: "action"
        }
    ];
    return [...columns, ...cols, ...actionColumns];
};

export const arrayEquals = (a, b) => {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
};

export const DeleteDialog = ({ onHide, Url }) => {
    const { id: primaryKey } = useParams();
    const [visible, setVisible] = useState(false);
    
    const onDelete = async () => {
        const { success, message } = await delete_(`${Url}${primaryKey}`);
        if (!success) {
            toast.push(<Notification type="danger" title={ message } />, {
                placement: "top-center"
            });
        }
        onHide();
    };
    useEffect(() => {
        setVisible(true);
    }, []);
    
    return (
        <ConfirmDialog
            isOpen={ visible }
            onClose={ onHide }
            onRequestClose={ onHide }
            type="danger"
            title="Confirmation"
            onCancel={ onHide }
            onConfirm={ onDelete }
            cancelText="Cancel"
            confirmText="Confirm"
            confirmButtonColor="red-600"
        >
            <p>
                The status of the data you selected will be changed. Do you want to continue?
            </p>
        </ConfirmDialog>
    );
};


export const ConfirmationDialog = ({ visible, onCancel, onConfirm, 
    title="The status of the data you selected will be changed. Do you want to continue?" }) => {
    
    return (
        <ConfirmDialog
            isOpen={ visible }
            onClose={ onCancel }
            onRequestClose={ onCancel }
            type="danger"
            title="Confirmation"
            onCancel={ onCancel }
            onConfirm={ onConfirm }
            cancelText="Cancel"
            confirmText="Confirm"
            confirmButtonColor="red-600"
        >
            <p>
                { title }
            </p>
        </ConfirmDialog>
    );
};


export const FilterSelectOption = ({ innerProps, label, data, isSelected }) => {
    return (
        <div
            className={ `flex items-center justify-between p-2 cursor-pointer ${
                isSelected
                    ? "bg-gray-100 dark:bg-gray-500"
                    : "hover:bg-gray-50 dark:hover:bg-gray-600"
            }` }
            { ...innerProps }
        >
            <div className="flex items-center gap-2">
                <Badge innerClass={ data.color } />
                <span>{ label }</span>
            </div>
            { isSelected && <HiCheck className="text-emerald-500 text-xl" /> }
        </div>
    );
};


export const FilterCustomControl = ({ children, ...props }) => {
    const selected = props.getValue()[0];
    return (
        <Control { ...props }>
            { selected && (
                <Badge
                    className="ltr:ml-4 rtl:mr-4"
                    innerClass={ selected.color }
                />
            ) }
            { children }
        </Control>
    );
};

export const DeviceSelectOption = ({ innerProps, label, data, isSelected }) => {
    return (
        <div
            className={ `flex items-center justify-between p-2 cursor-pointer ${
                isSelected
                    ? "bg-gray-100 dark:bg-gray-500"
                    : "hover:bg-gray-50 dark:hover:bg-gray-600"
            }` }
            { ...innerProps }
        >
            <div>
                <div>{ label }</div>
                <div>{ data?.gsm }</div>
            </div>
            { isSelected && <HiCheck className="text-emerald-500 text-xl" /> }
        </div>
    );
};


export const ImageSelectOption = ({ innerProps, label, data, isSelected }) => {
    return (
        <div
            className={ `flex items-center justify-between p-2 ${
                isSelected
                    ? "bg-gray-100 dark:bg-gray-500"
                    : "hover:bg-gray-50 dark:hover:bg-gray-600"
            }` }
            { ...innerProps }
        >
            <div className="flex items-center">
                <Avatar shape="circle" size={ 20 } src={ data?.image } />
                <span className="ml-2 rtl:mr-2">{ label }</span>
            </div>
        </div>
    );
};

export const ImageCustomControl = ({ children, ...props }) => {
    const selected = props.getValue()[0];
    return (
        <Control { ...props }>
            { selected && (
                <Avatar
                    className="ltr:ml-4 rtl:mr-4"
                    shape="circle"
                    size={ 18 }
                    src={ selected?.image }
                />
            ) }
            { children }
        </Control>
    );
};
