import { combineReducers } from "redux";
import auth from "./auth";
import base from "./base";
import theme from "./theme/themeSlice";

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        auth,
        base,
        theme,
        ...asyncReducers
    });
    return combinedReducer(state, action);
};

export default rootReducer;
