import { THEME_ENUM } from "constants/theme.constant";

export const themeConfig = {
    cardBordered: true,
    controlSize: "md",
    direction: THEME_ENUM.DIR_LTR,
    layout: {
        sideNavCollapse: false,
        type: THEME_ENUM.LAYOUT_TYPE_MODERN
    },
    mode: THEME_ENUM.MODE_LIGHT,
    navMode: THEME_ENUM.NAV_MODE_LIGHT,
    panelExpand: true,
    primaryColorLevel: 700,
    themeColor: "blue"

};
